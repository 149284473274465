/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import withIntegrationCardContext from 'hoc/withIntegrationCardContext';
import Slider from 'react-slick';

const Errorception = ({ sliderSettings }) => {
  const { img1 } = useStaticQuery(graphql`
    query {
      img1: file(relativePath: { eq: "marketplace/heap/1.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  const photos = [
    {
      img: img1.childImageSharp.fluid,
      alt: 'Errorception Integration',
      title: 'Integrate Errorception with LiveSession',
    },
  ];
  return (
    <>
      <Slider {...sliderSettings(photos)} className="without-dots">
        <div>
          <Img fluid={photos[0].img} alt={photos[0].alt} title={photos[0].title} />
        </div>
      </Slider>
      <div className="section">
        <h2>Overview</h2>
        <p>
          Heap is a product analytics tool to help you understand your users and make more
          data-driven decisions. Connect it to LiveSession and get even more in-depth insights!
        </p>
      </div>
      <div className="section">
        <h2>Benefits</h2>
        <ul className="checkmarks">
          <li>Access session replays directly from Heap event properties</li>
          <li>Gain more knowledge about your users </li>
          <li>Get the most from both Heap and LiveSession</li>
        </ul>
      </div>
      <h2>How to install</h2>
      <p>
        Just copy and paste a piece of code, as described in our{' '}
        <a href="/help/heap-integration/">help center</a>.
      </p>
    </>
  );
};

export default withIntegrationCardContext(Errorception);

Errorception.propTypes = {
  sliderSettings: PropTypes.func.isRequired,
};
